<template>
  <vx-card title="Import Customer">
    <vs-tabs>

      <vs-tab label="Customer">
        <div class="tab-text">
          <customer></customer>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Customer from "./customer";
export default {
  components: {
    Customer
  }
};
</script>